import React from 'react';
import { EditionPriceFragment } from '@generated/codegen';

export function FormattedEditionsPrice({
  price,
}: {
  price: Pick<
    EditionPriceFragment,
    'type' | 'formattedPrice' | 'formattedInterval'
  >;
}) {
  return (
    <>
      {price.formattedPrice.formatted}
      {price.type === 'recurring' && <>/{price.formattedInterval}</>}
    </>
  );
}

export function EditionsPrice({
  className,
  oneTimePrice,
  recurringPrice,
}: {
  className?: string | undefined;
  oneTimePrice: EditionPriceFragment | null | undefined;
  recurringPrice: EditionPriceFragment | null | undefined;
}) {
  if (!oneTimePrice && !recurringPrice) {
    return null;
  }
  return (
    <div>
      {oneTimePrice && (
        <>
          <span className={`whitespace-nowrap ${className ? className : ''}`}>
            <FormattedEditionsPrice price={oneTimePrice} />
          </span>
        </>
      )}
      {oneTimePrice && recurringPrice && <> or </>}
      {recurringPrice && (
        <span className={`whitespace-nowrap ${className ? className : ''}`}>
          <FormattedEditionsPrice price={recurringPrice} />
        </span>
      )}
    </div>
  );
}
