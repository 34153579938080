import React, { useState, useEffect } from 'react';
import { Heading } from '@components/ui/Heading';
import { Button } from '@components/ui/Button';
import { Anchor } from '@components/ui/Anchor';
import {
  AtelierLink,
  Pathname,
  useAtelierRouter,
} from '@components/common/routing';
import { CONTACT_EMAIL } from '@lib/constants';
import {
  useEditionsQuery,
  useCheckPaymentDetailsQuery,
  PaymentDetailsStatus,
  EditionsListProductFragment,
  EditionsQueryVariables,
} from '@generated/codegen';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import * as Sentry from '@sentry/nextjs';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { Check, X } from 'react-feather';
import Image from 'next/image';
import { SanityEditionsPage } from '@lib/sanity.server';
import { PortableText } from '@components/sanity/PortableText';
import { ArticleIntro } from '@components/article/ArticleIntro';
import { EditionsPrice } from '@components/editions/EditionsPrice';
import { PageSEO } from '@components/PageSEO';
import { useUserDoc } from '@lib/authentication';

function VerifyPaymentDialog({
  sessionId,
  onClose,
}: {
  sessionId: string;
  onClose: () => void;
}) {
  const { userDoc } = useUserDoc();
  const [pollingTimedOut, setPollingTimedOut] = useState(false);

  const { data, stopPolling, ...checkPaymentDetailsResult } =
    useCheckPaymentDetailsQuery({
      variables: { sessionId },
      skip: !userDoc,
      pollInterval: 1000,
      ssr: false,
    });

  const checkPaymentDetails = data?.checkPaymentDetails;
  const status = checkPaymentDetails?.status;
  const loading = Boolean(
    checkPaymentDetailsResult.loading || (!status && !pollingTimedOut)
  );
  const error = checkPaymentDetailsResult.error || pollingTimedOut;

  useEffect(() => {
    if (status === PaymentDetailsStatus.Succedeed) {
      stopPolling();
    }
  }, [status]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!status) {
        Sentry.captureMessage('checkPaymentDetails polling timed out', {
          level: Sentry.Severity.Error,
          extra: {
            sessionId,
          },
        });
        stopPolling();
        setPollingTimedOut(true);
      }
    }, 1000 * 10);
    return () => clearTimeout(timeoutId);
  }, [sessionId, status]);

  let title;

  if (loading) {
    title = 'Completing the payment';
  } else if (error) {
    title = 'Unable to create your order';
  } else {
    title = 'Payment completed';
  }

  return (
    <>
      <Transition show as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          static
          open
          onClose={() => {
            if (!loading) {
              onClose();
            }
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                tabIndex={1}
                className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl"
              >
                <div className="flex flex-col items-center justify center">
                  <>
                    <div
                      className={classNames(
                        {
                          'bg-green-100': !loading && !error,
                          'bg-gray-100': loading && !error,
                          'bg-red-100': error,
                        },
                        'mx-auto flex items-center justify-center h-12 w-12 rounded-full mb-4'
                      )}
                    >
                      {error && (
                        <X
                          className="w-6 h-6 text-red-600"
                          aria-hidden="true"
                        />
                      )}
                      {loading && !error && <LoadingSpinner />}
                      {!loading && !error && (
                        <Check
                          className="w-6 h-6 text-green-600"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="mb-2 text-lg font-medium leading-6 text-center text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    {error && (
                      <div className="text-center">
                        Please get in touch with us via the chat or send us an
                        email at{' '}
                        <Anchor underline href={`mailto:${CONTACT_EMAIL}`}>
                          {CONTACT_EMAIL}
                        </Anchor>
                        .
                      </div>
                    )}
                    <Transition
                      show={!loading && !error}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                      className="flex flex-col items-center justify-center"
                    >
                      <div className="mb-4">
                        <div className="space-y-1 text-center text-gray-500">
                          {checkPaymentDetails &&
                            checkPaymentDetails.receipt_email && (
                              <p>
                                Receipt sent to{' '}
                                {checkPaymentDetails.receipt_email}.
                              </p>
                            )}
                          <p>We will contact you for delivery.</p>
                          <p>
                            To see your purchase, go to{' '}
                            <AtelierLink href="/my-page/my-purchases">
                              <Anchor underline>your profile</Anchor>
                            </AtelierLink>
                            .
                          </p>
                        </div>
                      </div>
                      <div>
                        <Button
                          type="button"
                          variant="primaryYellow"
                          onClick={onClose}
                        >
                          Ok
                        </Button>
                      </div>
                    </Transition>
                  </>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function EditionsCard({
  data,
  itemPathname,
}: {
  data: EditionsListProductFragment;
  itemPathname: Pathname;
}) {
  // const size = `${data.width}×${data.height}cm`;
  return (
    <li>
      <AtelierLink
        href={{
          pathname: itemPathname,
          query: { slug: data.slug },
        }}
      >
        <a className="text-center group">
          {data.image && (
            <div className="mb-1">
              <Image
                src={data.image.src}
                width={data.image.width}
                height={data.image.height}
                alt=""
              />
            </div>
          )}
          {data.studio && <div className="font-normal">{data.studio.name}</div>}
          <h2>{data.name}</h2>
          {/* <div className="flex justify-around">
              <div>{data.editions} opplag</div>
              <div
                className="whitespace-nowrap"
                aria-label={`størrelse: ${size}`}
              >
                {size}
              </div>
            </div> */}
          <EditionsPrice
            oneTimePrice={data.oneTimePrice}
            recurringPrice={data.recurringPrice}
          />
          <div className="font-normal group-hover:underline">
            {data.forSale ? 'Order' : 'Sold'}
          </div>
        </a>
      </AtelierLink>
    </li>
  );
}

export interface EditionsProps {
  editionsPage: SanityEditionsPage;
}

export function Editions({
  editionsPage,
  editionsQueryVariables,
  itemPathname,
}: EditionsProps & {
  itemPathname: Pathname;
  editionsQueryVariables: EditionsQueryVariables;
}) {
  const router = useRouter();
  const { pushRoute, pathname } = useAtelierRouter();
  const { data } = useEditionsQuery({ variables: editionsQueryVariables });
  const sessionId = router.query.session_id?.toString();

  return (
    <div className="max-w-screen-lg px-4 mx-auto my-12 text-center">
      <PageSEO title={editionsPage.title} seo={editionsPage.seo} />
      {sessionId && (
        <VerifyPaymentDialog
          sessionId={sessionId}
          onClose={() => {
            const newQuery = { ...router.query };
            delete newQuery.session_id;
            pushRoute({ pathname, query: newQuery }, { shallow: true });
          }}
        />
      )}
      <Heading level="h1" size="xl" className="mb-8">
        {editionsPage.title}
      </Heading>
      {editionsPage.intro && (
        <ArticleIntro blocks={editionsPage.intro} className="mx-auto mb-12" />
      )}
      {editionsPage.body && (
        <PortableText
          blocks={editionsPage.body}
          className="mx-auto mb-12 prose prose-lg lg:prose-lg"
        />
      )}
      {data && (
        <ul
          data-testid="EditionsList"
          className="grid gap-4 sm:grid-cols-2 md:grid-cols-3"
        >
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-dahl',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_dahl.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Kaja Dahl</div>
                  <h2>Stein på Stein</h2>
                  <h2>kr 3675</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-mikkelsgard',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_mikkelsgard.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Henrik Mikkelsgård</div>
                  <h2>Nes</h2>
                  <h2>kr 5775</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-gellein',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_gellein.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Anette Gellein</div>
                  <h2>Melting Time</h2>
                  <h2>kr 4200</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-terins',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_terins.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Love Terins</div>
                  <h2>And Flowers, Always Flowers</h2>
                  <h2>kr 4800</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-hvidsten',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_hvidsten.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Ole Fredrik Hvidsten</div>
                  <h2>Jeanne D’arc, you crazy braud</h2>
                  <h2>kr 3500</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-barrios',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_barrios.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Javier Barrios</div>
                  <h2>Golden Age</h2>
                  <h2>kr 8400</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-pigao',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_pigao.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Pearla Pigao</div>
                  <h2>I as an other</h2>
                  <h2>kr 4500</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-aamodt',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_aamodt.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Nicolay Aamodt</div>
                  <h2>Over</h2>
                  <h2>kr 4500</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-04',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_04.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Maiken Stene</div>
                  <h2>Copies on a stroll</h2>
                  <h2>kr 5900</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/vika',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_vika.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Andrea Scholze</div>
                  <h2>Utsikten fra innsiden</h2>
                  <h2>kr 5500</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-14',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_14.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Henrik Kleppe</div>
                  <h2>Pink avenue</h2>
                  <h2>kr 5500</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {pathname === '/editions' && (
            <li>
              <AtelierLink
                href={{
                  pathname: '/editions-15',
                }}
              >
                <a className="text-center group">
                  <div className="mb-1">
                    <Image
                      src={'/editions_15.webp'}
                      width={320}
                      height={320}
                      alt=""
                    />
                  </div>
                  <div className="font-normal">Kiyoshi Yamamoto</div>
                  <h2>Sketches for a Tapestry</h2>
                  <h2>kr 5500</h2>
                </a>
              </AtelierLink>
            </li>
          )}
          {data.editions.map((item) => {
            return (
              <EditionsCard
                key={item.id}
                data={item}
                itemPathname={itemPathname}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
}
