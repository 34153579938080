import { urlForImage } from '@lib/sanity';
import type { SanitySEO } from '@lib/sanity.server';
import { NextSeo, NextSeoProps } from 'next-seo';
import React from 'react';

export function PageSEO({
  title,
  seo,
}: {
  title: string;
  seo: SanitySEO | undefined;
}) {
  const props: NextSeoProps = {
    title,
  };
  if (seo) {
    const { description, openGraphImage } = seo;
    props.description = description;
    props.openGraph = {
      description,
    };
    if (openGraphImage) {
      const width = 1200;
      const height = 630;
      const url = urlForImage(openGraphImage).width(width).height(height).url();
      if (url) {
        props.openGraph.images = [{ url, width, height }];
      }
    }
  }
  return <NextSeo {...props} />;
}
