import { PortableText } from '@components/sanity/PortableText';
import React from 'react';
import type { SanityPortableText } from '@lib/sanity.server';
import classNames from 'classnames';

export function ArticleIntro({
  blocks,
  className,
}: {
  blocks: SanityPortableText;
  className?: string;
}) {
  return (
    <PortableText
      blocks={blocks}
      className={classNames(className, 'font-serif mb-4')}
      size="lg"
    />
  );
}
